import React from "react";
import "./portfolio.css";
import AnimatedLetters from "../animatedLetters/AnimatedLetters";
import "../animatedLetters/animatedLetters.css";
// CLIENTS IMAGES
import tattoodo from "../../assets/tattoodo.jpeg";
import perci from "../../assets/perci.png";
import naytal from "../../assets/naytal.png";
import apidae from "../../assets/apidae-digital.png";
import dcypher from "../../assets/dcypher.jpeg";
import stefan from "../../assets/stefan.png";
import genki from "../../assets/genki.png";
import stolp from "../../assets/stolp.png";
import globaldatabase from "../../assets/globaldatabase.jpeg";
import dorms from "../../assets/dorms.png";
import yanas from "../../assets/yanas.png";
import beauty from "../../assets/beauty-pie.png";
import casumo from "../../assets/casumo.png";
import raketech from "../../assets/raketech.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Autoplay } from 'swiper';

const data = [
  {
    id: 1,
    image: tattoodo,
    title: "Tattoodo",
    website: "https://www.tattoodo.com/"
  },
  {
    id: 2,
    image: perci,
    title: "Perci",
    website: "https://www.percihealth.com/"
  },
  {
    id: 3,
    image: naytal,
    title: "Naytal",
    website: "https://www.linkedin.com/company/naytal/"
  },
  {
    id: 4,
    image: apidae,
    title: "Apidae Digital",
    website: "https://apidae.digital/"
  },
  {
    id: 5,
    image: dcypher,
    title: "Dcypher",
    website: "https://dcypher.me/"
  },
  {
    id: 6,
    image: stefan,
    title: "Qlick",
    website: "https://www.qlick.xyz/"
  },
  {
    id: 7,
    image: genki,
    title: "Genki",
    website: "https://genki.world/"
  },
  {
    id: 8,
    image: stolp,
    title: "Stolp",
    website: "https://stolp.com/"
  },
  {
    id: 9,
    image: globaldatabase,
    title: "Global Database",
    website: "https://www.globaldatabase.com/"
  },
  {
    id: 10,
    image: dorms,
    title: "Dorms",
    website: "https://www.dorms.com/"
  },
  {
    id: 11,
    image: yanas,
    title: "Yanasjewellery",
    website: "https://www.yanasjewellery.com/"
  },
  {
    id: 12,
    image: beauty,
    title: "Beauty Pie",
    website: "https://www.beautypie.com/"
  },
  {
    id: 13,
    image: casumo,
    title: "Casumo",
    website: "https://www.casumo.com/en/"
  },
  {
    id: 14,
    image: raketech,
    title: "Raketech",
    website: "https://raketech.com/"
  }
];

const Portfolio = React.forwardRef((props, ref) => {
  return (
    <section id="portfolio" ref={ref}>
      <h5>Where I've Made An Impact</h5>
      <h2>
        <AnimatedLetters strArray={["Recent Collaborations"]} slowFactor={1} />
      </h2>

      <Swiper
        className="container portfolio__swiper"
        // install Swiper modules
        modules={[Autoplay, Pagination]}
        spaceBetween={10}
        slidesPerView={5}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
            // spaceBetween: 10
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 5,
          }
        }}
      >
        {data.map(({ id, image, title, website }) => {
          return (
            <SwiperSlide key={id} className="portfolio__slide">
              <div>
                <a href={website} target="_blank" rel="noopener noreferrer">
                  <img src={image} alt={title} />
                </a>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
});

export default Portfolio;